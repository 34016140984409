@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.recruiterViewCardDiv {
	background-color: white;

	flex-wrap: wrap;
	justify-content: space-evenly;
}
.recruiterViewColumn {
	height: calc(100vh - 105px);

	padding-left: 1vw !important;
	padding-right: 1vw !important;
}
.filterSearchLabel {
	margin-bottom: 0px !important;
}

.recruiterViewLeftColumn {
	height: calc(100vh - 105px);

	padding-right: 1vw !important;
	padding-left: 0px !important;
}

.recruiterViewColumn50vw {
	height: calc(100vh - 105px);
	width: 50vw !important;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
}

.recruiterViewColumn65vw {
	height: calc(100vh - 105px);
	width: 66.5vw !important;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
}

.recruiterViewColumn75vw {
	height: calc(100vh - 105px);
	width: 77.5vw !important;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
}
.recruiterViewColumn95vw {
	height: calc(100vh - 105px);
	width: 95vw !important;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
}
.recruiterViewHeader {
	line-height: 40px;
	font-size: 30px;
	text-align: center;
	background-color: #4b9cd3;
	color: #ffffff;
	margin-bottom: 0;
}

.BreeSerif {
	font-family: 'Bree Serif';
}

.LogoSmallDiv {
	padding-left: 6vw !important;
	padding-right: 6vw !important;
	width: 100vw !important;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #4b9cd3;
}
.LogoLargeDiv {
	padding-left: 6vw !important;
	padding-right: 6vw !important;
	width: 100vw !important;

	padding-top: 25px;
	padding-bottom: 25px;
	background-color: #4b9cd3;
}
.recruiterViewTag {
	background-color: #e5e5e5;
	padding: 3px;
	margin: 3px;
	font-size: 11px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.recruiterModal {
	margin: 0px !important;
	max-width: 90vw !important;
	margin-left: 5vw !important;
	margin-right: 5vw !important;
	overflow-y: hidden !important;
}
.recruiterModalBackGround {
	overflow-y: hidden !important;
	height: 100vh !important;
}

.recruiterViewIcon {
	transition: all 0.2s ease-in-out;

	line-height: 40px;
}
.recruiterViewIcon:hover {
	transform: scale(1.1);
}

.resumeViewDiv {
	width: 90vw;
	height: calc(100vh);
	height: 900px;
	background-color: #e5e5e5;
}

.mb-30 {
	margin-bottom: 30px;
}
.resumeImage {
	overflow-y: auto;
	width: 50vw;
	height: calc(100vh);
	padding-left: 3vw;
	border: none;
}
.resumeViewImageDiv {
	height: calc(100vh);
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow-y: auto;
}
.resumeViewImageDiv::-webkit-scrollbar {
	display: none;
}

.resumeViewDiv {
	padding-bottom: 5px;
	padding-top: 5px;
}
.resumeViewHeader {
	background-color: #4b9cd3;
	width: 40vw;
	text-align: center;
	color: white;
}
.resumeViewContainer {
	height: calc(100vh - 105px);
	overflow: hidden !important;
}
.vw-100 {
	width: 100vw !important;
}

.resumeViewDropDownDiv {
	width: 40vw;
	height: 40px;
	color: #ffffff;
	background-color: #4b9cd3;
	display: flex;
	justify-content: space-between;
}
.resumeViewItems {
	width: 40vw;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	flex-wrap: wrap;
}
.classInfoDiv {
	width: 40vw;
	height: 40px;
	font-size: 25px;
	background-color: #e5e5e5;
}
.resumeViewDropDownText {
	font-size: 25px;
	line-height: 40px;
	padding-left: 5px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.resumeViewDropDownIcon {
	height: 25px !important;
	width: 25px !important;
	margin-right: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
}
.resumeViewNotes {
	width: 40vw;
	height: 200px;
	padding: 5px !important;
	background-color: #ffffff;
}
.resumeViewRightPanel {
	height: calc(100vh);
	overflow-y: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.resumeViewRightPanel::-webkit-scrollbar {
	display: none;
}

.resumeViewDropDowns {
	overflow-y: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.resumeViewDropDowns::-webkit-scrollbar {
	display: none;
}

.recruiterViewItems {
	font-size: 25px;
	color: #25282b;
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 15px;
	margin-left: 15px;
	margin-top: 5px;
	margin-bottom: 0px;
	line-height: 40px;
	background-color: #ffffff;
	border-radius: 15px;
	box-shadow: 0px 4px 4px #25282b 0.25;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.filterNameSearchDiv {
	height: 80px;
}

.p-10px {
	padding: 10px;
}
.recruiterSpinnerDiv {
	color: #4b9cd3;
	height: calc(100vh - 105px);
	align-items: center;
	overflow: hidden;
	background-color: #13294b;
}

.spinnerModal {
	background-color: rgba(0, 0, 0, 0.0001) !important;
	color: #4b9cd3;
	height: calc(100vh - 105px);
	align-items: center;
	overflow: hidden;
}

.spinnerViewDialog > .modal-content {
	background-color: rgba(0, 0, 0, 0.0001) !important;
	border: none;
}

.spinnerViewBackdrop {
	opacity: 0.01 !important;
}

.recruiterSpinner {
	height: 100px !important;
	width: 100px !important;
	font-size: 50px;
}
.candidateSpinner {
	height: 100px !important;
	width: 100px !important;
	font-size: 50px;
	position: absolute;
	top: calc(50% - 50px);
	color: #4b9cd3;
	left: calc(50% - 50px);
	z-index: 10000;
}

.recruiterViewRow {
	margin-right: 0px !important;
	margin-left: 0px !important;
}
.recruiterViewAnimatedDiv {
	position: relative !important;
}

.resumeViewSaveIcon:hover {
	transform: scale(1.1);
}

.App {
  margin: 0px;
  height: 100vh !important;
  overflow-y: hidden;
  background-color: #13294b;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 1em;
  display: none;
}
.authContainer {
	padding-top: 18px;
	display: flex;
	justify-content: center;
	background-size: cover;
	min-height: calc(100vh - 6vh);
	max-height: calc(100vh - 9vh);
	min-width: 100vw;
	max-width: 100vw;
}

.authComponent {
	color: #ffffff;
	background-color: #13294b;
	position: relative;
	text-align: center;
	max-width: 50vw;
	padding: 0;
	margin: 0;
	border-radius: 8px;
}
.loginError {
	color: #ef426f;
	margin: 15px !important;
}

.authBtn {
	/* background-color: #4b9cd3;
  font-size: 30px;
  padding-left: 15px;
  padding-right: 15px;

  padding: 10px;
  padding-bottom: 10px;
  margin-top: 40px;
  color: white;
  border: none;
  border-radius: 10px; */
	background-color: #4b9cd3;
	height: calc(1.5em + 0.75rem + 2px);
	color: white;
	min-width: 15vw;
	max-width: 20vw !important;
	margin-top: 30px;
	border: none;
}

.authBtn:hover {
	background-color: #2e83bd;
}

/* .form-control-auth {
  padding: 20px !important;
  margin: auto !important;
  margin-top: 40px !important;
  min-width: 15vw !important;
  max-width: 20vw !important;
} */
.studentSignUp {
	color: #4b9cd3 !important;
}
.authForm {
	position: relative;
	height: auto;
	padding-top: 45px;
}

.authLink {
	padding-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
}
.forgotLink {
	padding: 15px;
	padding-top: 0;
}
.loginHeader {
	font-size: 50px !important;
}

.nav {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6vw !important;
  padding-right: 6vw !important;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 60px;
  max-height: 80px;
  background-color: #4b9cd3;
  color: white;
  
}
.nav-links{
  margin-bottom: 0;
}
.navContainer{
  padding-bottom: 25px;
  background-color: #13294b;
}


.authModal {
	max-width: 60vw !important;
	margin-left: 20vw;
	margin-right: 20vw;
}

.authModalHeader {
	background-color: #4b9cd3;
	color: #fff;
}

.authModalSectionTitle {
	font-size: 30px;
}

.full-panel {
  margin-top: 0;
  padding-top: 0;
  background-color: #13294b;
  min-height: calc(100vh - 8vh);
  max-height: calc(200vh - 8vh);
  min-width: 100vw;
  max-width: 100vw;
}
.left-panel {
  color: white;
  /* padding: 2%; */
  margin: 2%;
  margin-top: 0px;
  margin-left: 4%;
  /*min-height: 84vh; */
  height: calc(100vh - 105px) !important;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
}
.left-panel::-webkit-scrollbar {
  display: none;
}
#ControlSelect1 {
  height: auto;
}

.minorsCheckboxes {
  height: 40vh;
  overflow-y: scroll;
}

.headersForEachType {
  line-height: 72.5px !important;
  font-size: 30px !important;
  padding-left: 15px;
}

.right-panel {
  color: white;
  background-color: #4b9cd3;
  height: calc(100vh - 105px);
  overflow-y: auto;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 0px;
  margin-right: 4%;
}

.my-information-container {
  background-color: white;
  color: black;
  min-height: 100%;
  width: 100%;
}
/* 
.card-setting {
  /* width: 96%; 
} */

.basic-information-form {
  padding: 3%;
}

.data-row-btn {
  width: 50px;
  display: inline-block;
  margin-left: 15px;
}
.data-row-label {
  font-size: 30px !important;
  width: 100% !important;
  max-width: 100% !important;
}
.inputGroup {
  width: auto !important;
  margin-right: 20px !important;
}
.radioInput {
  margin-left: 15px !important;
  margin-top: 15px !important;
}
.currentlySeeking {
  margin-bottom: 0px !important;
  line-height: 1.5 !important;
}
.formBtn {
  height: 40px !important;
  line-height: 30px;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  margin-top: 15px !important;
  margin-left: 20px !important;
  background-color: #4b9cd3 !important;
  border: none !important;
}
.multiBtn {
  height: 40px !important;
  max-width: 77.18px !important;
  line-height: 30px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  margin-top: 15px !important;
  margin-left: 10px !important;
  background-color: #4b9cd3 !important;
  border: none !important;
}
.formRow {
  display: block !important;
}

.formColumn {
  width: 100%;
}
.textForm {
  margin-left: 10px !important;
  margin-right: 10px !important;
  height: 40px !important;
}
.data-row-control {
  width: 60vw;
  max-width: 90%;
  display: inline-block;
}

.accordion-toggle-selector {
  background-color: "#E5E5E5";
}

.updateButtonDiv {
  text-align: center;
  margin: 4%;
}
.my-information-accordion {
  padding: 0px !important;
}
.accordionHeader {
  height: 72.5px !important;
  color: white;
  border-radius: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
h3 {
  font-size: inherit !important;
}
.uploadButton {
  margin-left: 10px !important;
  margin-right: 10px !important;
  background-color: #4b9cd3 !important;
}
.checkBoxes {
  margin-left: 10px !important;
}
.list-group-item {
  background-color: #4b9cd3 !important;
  height: 40px !important;
  line-height: 30px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: white !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 50px;
  margin-left: 10px !important;
  margin-bottom: 10px;
}

.form-control {
  padding: 10px;
  margin: auto;
  margin-top: 15px;
  min-width: 10vw;
  max-width: 15vw;
  width: 220px;
}

.SideCardBody {
	/*flex: 1 1 auto; */
	min-height: 1px;
	display: flex;
	/*flex-direction: column;*/
	align-content: center;
	flex-wrap: wrap;
	justify-content: center;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
	padding-top: 1vh !important;
	border-radius: 0px;
}

#SideCardCard {
	width: 25vw;
	height: calc(100vh-105px);
	height: auto;
	background-color: white;
	border: none;
}

.SideCardProfileHeader {
	display: flex;
	justify-content: center !important;
	padding: 0 !important;
	background-color: #4b9cd3 !important;
	width: 25vw;
	border-radius: 0px !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.flex-container {
	display: flex;
}

.card-header {
	padding: 0;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
#ProfileDiv {
	height: '190px';
	width: '25vw';
	flex-wrap: 'wrap';
	padding-top: '20px';
}
.emailDiv {
	width: inherit;
	display: flex;
	justify-content: center;
	/* font-family: Droid Sans; */
	font-style: normal;
	font-weight: normal;
	color: white;
	font-size: 20px;
	padding-top: 5px;
}
.nameDiv {
	padding-top: 10px;
	margin-left: 10px;
	height: 100px;
	text-align: center;
	display: flex;
	flex-flow: column;
	line-height: 30px;
	color: white;
	width: '12vw';
	display: 'flex';
	flex-wrap: 'wrap';
	justify-content: 'center';
}
.imgDiv {
	height: 100px;
	margin-right: 10px;
	display: flex;
	align-items: center;
}
.nameText {
	font-style: 'normal';
	font-weight: 'normal';
	font-size: '40px';
	font-weight: 400px;
	line-height: '40px';
	display: 'flex';
	align-items: 'center';
	text-align: 'center';
	min-width: '51%';
	width: 'auto';
	text-align: 'center';
	margin-bottom: 0px;
}

.SideResumePdfImage {
	border-radius: 150px;
	height: 6vw !important;
	width: 6vw !important;

	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.settingsIcon {
	position: absolute;
	left: 5px;
	top: 5px;
	transition: all 0.2s ease-in-out;
}
.settingsIcon:hover {
	-webkit-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* .modal-dialog {
  max-width: 50vw !important;
} */
.sideResumeBoxCard {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.updateBtn {
	/* color: teal; */
	height: 40px !important;
	line-height: 30px;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
	margin-top: 15px !important;
	margin-left: 20px !important;
	background-color: #4b9cd3 !important;
	border: none !important;
}

.selectOneInput {
	margin-left: 10px !important;
	margin-right: 10px !important;
	height: 40px !important;
	width: 220px !important;
}

.studentViewModal{
    max-width: 80vw !important;
    margin-left: 10vw;
    margin-right: 10vw;

}

.studentModalHeader{
    background-color: #4b9cd3;
    color:#fff;
}

.studentModalSectionTitle{
    font-size: 30px;
    
}
.filter {
	margin-left: 3vw;
	position: relative !important;
}
.filterAnimatedDiv {
	position: relative !important;
}
.filterHeader {
}

.filterArrowDiv {
	top: 100px;
	right: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;

	margin: 0;
	padding: 0;
}
.dummyArrowDiv {
	top: 100px;
	right: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;
	margin: 0;
	z-index: 100;

	padding: 0;
}

.dummyArrowDiv:hover {
	transform: scale(1.1);
}
.filterArrowDiv:hover {
	transform: scale(1.1);
}

.filterArrowIcon {
	z-index: 100;

	height: 25px !important;
	width: 25px !important;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
}
.filterDummyColumn {
	height: 50vh;
	width: 1.5vw;
	background-color: #ffffff;
}
.filterDummyHeader {
	background-color: #4b9cd3;
	height: 40px;
}
.filterSearchBar {
	background-color: #ffffff;
	width: 15vw;
	position: relative;
	border-bottom: 1px solid #e5e5e5;
}
.filterVertical {
	position: absolute;
	top: 50%;
	width: 15vw;
	transform: translate(0, -50%);
}
.filterInput {
	width: 10vw;
	padding-left: 10px;
	padding-right: 10px;
	height: 20px;
	background-color: #e5e5e5;
	border-radius: 50px;
	border: none;
}

.filterSearchName {
	margin-right: 3px;

	font-size: 15px;
	color: #13294b;
	line-height: 20px;
	margin-bottom: 0;
}
.filterIcon {
	transform: translate(-20px);
	line-height: 20px;
	height: 15px !important;
	width: 15px !important;
	font-size: 1.5em;
	margin-bottom: 5px;
	margin-top: 2.5px;
}

.filterDropDownHeader {
	background-color: #4b9cd3;
	align-items: center;
	height: auto;
	min-height: 40px;
	width: 15vw;
}
.filterDropDownTitle {
	padding-left: 10px;
	line-height: 20px;
	margin-bottom: 0px !important;
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
	font-size: 15px;
	color: #ffffff;
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
	text-overflow: ellipsis !important;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.filterDropDownIcons {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	align-items: center;
	font-size: 1.5em;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
}
.filterDropDownIcons:hover {
	transform: scale(1.1);
}
.filterCheck {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	color: #4b9cd3;
}
.filterGrey {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	color: #e5e5e5;
}

.filterItemText {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
	text-overflow: ellipsis !important;

	margin-left: 20px;
	font-size: 15px;
	line-height: 20px;
	align-items: center;
	margin-bottom: 0px;
}
.filterItem {
	background-color: #ffffff;
	width: 15vw;
	align-items: center;
	height: auto;
}
.filterList {
	margin: 0;
	padding-left: 0;
	list-style: none;
}
ul {
	list-style: none;
}
.filterLi {
	height: 40px;
	padding-left: 0;
	border-collapse: collapse;
}
.filterScroll {
	max-height: calc(100vh - 145px - 15px) !important;
	overflow-y: auto !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.filterScroll::-webkit-scrollbar {
	display: none;
}

.myListIcons {
	height: 25px !important;
	width: 25px !important;
	margin-right: 5px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	transition: all 0.2s ease-in-out;
}
.myListIcons:hover {
	transform: scale(1.1);
}
.myListInput {
	width: 10vw;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 3px;
	height: 20px;
	background-color: #e5e5e5;
	border-radius: 50px;
	border: none;
}
.myListLabel {
	line-height: 20px;
	margin-right: 3px;
	margin-bottom: 0px;
	color: #13294b;
	font-weight: 500;
}
.myListForm {
	height: 80px;
	position: relative;
	overflow-wrap: normal;
}
.myListFormDiv {
	margin-top: 10px;
	margin-bottom: 10px;
}

.myListSubmit {
	background-color: #4b9cd3 !important;
	height: 20px;
	line-height: 20px;
	line-height: 20px !important;
	padding: 0px !important;
	display: flex !important;
	align-items: center !important;
	border-radius: 3px !important;
	border: none !important;
}
.submitIcons {
	height: 20px !important;
	width: 20px !important;
	line-height: 20px !important;
}

.newListIcons {
	height: 25px !important;
	width: 25px !important;
	margin-right: 5px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	transition: all 0.2s ease-in-out;
	color: white;
}
.myListHeaderDiv {
	width: 25vw;
	background-color: #4b9cd3;
}
.newListIcons:hover {
	transform: scale(1.1);
}
.newListDiv {
	margin-left: auto;
	float: right !important;
	position: relative !important;
}
.myListTitle {
	padding-left: 10px;
	line-height: 40px;
	font-size: 20px;
	color: #13294b;
}
.myListTitleHeader {
	background-color: #e5e5e5;
	height: 40px;
}

.myListsItem {
	height: 40px;
	background-color: #ffffff;
	border-bottom: 1px solid #e5e5e5;
}

.myListsDropDownItemName {
	line-height: 40px;
	font-size: 20px;
	transform: translate(-3px);
	z-index: 100;
}
.myListsDropDownItemImg {
	width: 36px;
	height: 36px;
	margin: 2px;
	border-radius: 150px;
}
.myListsArrowDiv {
	top: 100px;
	left: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;

	margin: 0;
	padding: 0;
}
.myListsArrowDiv:hover {
	transform: scale(1.1);
}
.myListsArrowIcon {
	height: 25px !important;
	width: 25px !important;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
	z-index: 100;
}
.myListsDummyArrowDiv {
	z-index: 100;

	top: 100px;
	left: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;
	margin: 0;
	padding: 0;
}
.myListsDummyArrowDiv:hover {
	transform: scale(1.1);
}
.myListsDummyHeader {
	background-color: #4b9cd3;
	height: 40px;
}
.myListsDummyColumn {
	padding-right: 0px !important;
	height: 50vh;
	width: 1.5vw;
	background-color: #ffffff;
	position: absolute;
}

.myListsContainerDiv {
	height: calc(100vh - 105px - 15px);
}
.myListsScroll {
	height: calc(100vh - 145px - 15px) !important;
	background-color: white;
	overflow-y: auto !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.myListsScroll::-webkit-scrollbar {
	display: none;
}

.cardIconDiv {
	position: absolute;
	bottom: 0;
	width: 148px;
	border-radius: 15px;
	background-color: white;
}

.candidateCard {
	border-radius: 15px !important;
	border: 1px solid #e5e5e5;
	height: 260px;
	width: 150px;
	margin: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
}
.candidateCard:hover {
	z-index: 150;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	transform: scale(1.05);
}

.cardImg {
	margin-left: 3px;
	margin-top: 3px;
	margin-bottom: 3px;
	object-fit: cover;
}
.cardHeaderTextDiv {
	position: relative;
	top: 15%;
	text-align: center;
}
.cardHeaderText {
	font-size: 15px !important;
	color: #4b9cd3;
	margin: 0;
	text-align: center;
	text-overflow: ellipsis !important;
	overflow: hidden;
	white-space: nowrap !important;
	max-height: 20px;
}
.cardSchoolHeaderText {
	font-size: 15px !important;
	color: #4b9cd3;
	margin: 0;
	text-align: center;
	text-overflow: ellipsis !important;
}

.cardHeader {
	background-color: #13294b !important;
	max-height: 44px;
	margin: 0;
}
.cardTagContainer {
	padding: 10px;
	max-height: 125px;
	overflow-y: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.cardTagContainer::-webkit-scrollbar {
	display: none;
}

.candidateCardWrapper {
	background-color: #ffffff;
	position: relative;
}

.candidateDropDown {
	z-index: 150;
}
.recruiterViewCardWrapper {
	background-color: #ffffff !important;
	max-height: calc(100vh - 145px - 15px) !important;
	overflow-y: auto !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.recruiterViewCardWrapper::-webkit-scrollbar {
	display: none;
}
.cardHeaderUNC {
	background-color: #4b9cd3 !important;
	max-height: 44px;
	margin: 0;
}
.cardHeaderTextUNC {
	font-size: 15px !important;
	color: white;
	margin: 0;
	text-align: center;
	text-overflow: ellipsis !important;
}

.cardHeaderOther {
	background-color: #13294b !important;
	max-height: 44px;
	margin: 0;
}
.cardHeaderTextOther {
	font-size: 15px !important;
	color: #4b9cd3;
	margin: 0;
	text-align: center;
	text-overflow: ellipsis !important;
}

.each-list {
  position: relative;
}

.admin-card-name {
  display: inline-flex;
  font-weight: bold;
  font-size: larger;
  min-width: 15%;
}

.admin-recruiter-card-name {
  font-weight: bold;
  font-size: larger;
  min-width: 15%;
}

.admin-resume-access-list {
  padding-left: 2%;
  padding-right: 2%;
  display: inline-flex;
  list-style-type: none;
  font-weight: normal !important;
}

.detailed-modal {
  float: right;
  margin-top: 1%;
  display: inline-flex;
}

.admin-input-box {
  min-width: 50% !important;
  /* width: 50% !important; */
  max-width: 100% !important;
  margin-top: 0px !important;
}

.admin-panel {
  color: white;
  background-color: #4b9cd3;
  min-height: calc(100vh - 155px) !important;
  /* height: calc(100vh - 105px); */
  /* overflow-y: auto; */
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  /* padding-right: 0px !important; */
  /* padding-left: 0px !important; */
  margin-top: 1%;
  /* margin-right: 4%; */
}

.admin-heading {
  margin-left: 1%;
  padding-left: 1%;
  padding-top: 1%;
  display: inline-flex;
  min-width: 100%;
}

.admin-card-accordion-toggle {
  padding-left: 1%;
  padding-right: 1%;
}

.unordered-list-students {
  padding-left: 1%;
  padding-right: 1%;
}

.admin-master-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 105px);
  /* max-height: fit-content !important; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.admin-master-container::-webkit-scrollbar {
  display: none;
}

.admin-card-text {
  padding-left: 2% !important;
  padding-right: 2% !important;
}
.admin-new-recruiter-admin-buttons {
  margin-left: 1%;
  padding-left: 1%;
  padding-bottom: 1%;
  display: inline-flex;
  min-width: 100%;
}

.admin-new-recruiter-button {
  /* position: absolute; */
  /* float: right !important; */
  /* right: 2% !important; */
  /* margin-right: 5%; */
}
.admin-new-admin-button {
  /* position: absolute; */
  /* float: right !important; */
  /* right: 2% + 15px !important; */
}

.admin-new-recruiter-modal-form {
  margin: 2% !important;
}

.admin-new-recruiter-modal-form-input {
  margin: 2% !important;
  min-width: 90%;
}

.accountChangeContainer {
	background-color: white;
	width: 85vw;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 3%;
	height: calc(100vh - 105px);
}
.accountSettingsTitle {
	font-size: 30px;
	margin-top: 0px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 15px;
	color: #fff;
	background-color: #4b9cd3;
	width: 100%;
}
.arrowIcon {
	transition: all 0.2s ease-in-out;
	color: black;
}
.arrowIcon:hover {
	transform: scale(1.1);
}
.accountChangeBtn {
	background-color: #4b9cd3;
	height: calc(1.5em + 0.75rem + 2px);
	color: white;
	min-width: 150px;
	max-width: 20vw !important;
	margin-top: 30px;
	border: none;
	width: 100%;
}

.emailChangeForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.changeInfoHeader {
	color: black;
	font-size: 20px;
	text-align: center;
	margin-bottom: 0px;
}

.passwordChangeContainer {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	min-width: 500px;
	margin-left: 0px;
	margin-right: 0px;
	max-width: 20vw !important;
}
.emailChangeContainer {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	min-width: 500px;
	margin-left: 0px;
	margin-right: 0px;
	max-width: 20vw !important;
}

.accountChangeContainer {
	background-color: white;
	width: 85vw;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 3%;
	height: calc(100vh - 105px);
}
.accountSettingsTitle {
	font-size: 30px;
	margin-top: 0px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 15px;
	color: #fff;
	background-color: #4b9cd3;
	width: 100%;
}
.arrowIcon {
	transition: all 0.2s ease-in-out;
	color: black;
}
.arrowIcon:hover {
	transform: scale(1.1);
}
.accountChangeBtn {
	background-color: #4b9cd3;
	height: calc(1.5em + 0.75rem + 2px);
	color: white;
	min-width: 150px;
	max-width: 20vw !important;
	margin-top: 30px;
	border: none;
	width: 100%;
}

.emailChangeForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.changeInfoHeader {
	color: black;
	font-size: 20px;
	text-align: center;
	margin-bottom: 0px;
}

.passwordChangeContainer {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	min-width: 500px;
	margin-left: 0px;
	margin-right: 0px;
	max-width: 20vw !important;
}
.emailChangeContainer {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	min-width: 500px;
	margin-left: 0px;
	margin-right: 0px;
	max-width: 20vw !important;
}

