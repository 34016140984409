.filter {
	margin-left: 3vw;
	position: relative !important;
}
.filterAnimatedDiv {
	position: relative !important;
}
.filterHeader {
}

.filterArrowDiv {
	top: 100px;
	right: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;

	margin: 0;
	padding: 0;
}
.dummyArrowDiv {
	top: 100px;
	right: -1vw;
	display: flex;
	justify-content: center;
	align-self: center;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 150px;
	border: 1px solid #13294b;
	background-color: #ffffff;
	transition: all 0.2s ease-in-out;
	margin: 0;
	z-index: 100;

	padding: 0;
}

.dummyArrowDiv:hover {
	transform: scale(1.1);
}
.filterArrowDiv:hover {
	transform: scale(1.1);
}

.filterArrowIcon {
	z-index: 100;

	height: 25px !important;
	width: 25px !important;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
}
.filterDummyColumn {
	height: 50vh;
	width: 1.5vw;
	background-color: #ffffff;
}
.filterDummyHeader {
	background-color: #4b9cd3;
	height: 40px;
}
.filterSearchBar {
	background-color: #ffffff;
	width: 15vw;
	position: relative;
	border-bottom: 1px solid #e5e5e5;
}
.filterVertical {
	position: absolute;
	top: 50%;
	width: 15vw;
	transform: translate(0, -50%);
}
.filterInput {
	width: 10vw;
	padding-left: 10px;
	padding-right: 10px;
	height: 20px;
	background-color: #e5e5e5;
	border-radius: 50px;
	border: none;
}

.filterSearchName {
	margin-right: 3px;

	font-size: 15px;
	color: #13294b;
	line-height: 20px;
	margin-bottom: 0;
}
.filterIcon {
	transform: translate(-20px);
	line-height: 20px;
	height: 15px !important;
	width: 15px !important;
	font-size: 1.5em;
	margin-bottom: 5px;
	margin-top: 2.5px;
}

.filterDropDownHeader {
	background-color: #4b9cd3;
	align-items: center;
	height: auto;
	min-height: 40px;
	width: 15vw;
}
.filterDropDownTitle {
	padding-left: 10px;
	line-height: 20px;
	margin-bottom: 0px !important;
	height: min-content;
	font-size: 15px;
	color: #ffffff;
	width: fit-content !important;
	text-overflow: ellipsis !important;
	user-select: none;
}
.filterDropDownIcons {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	align-items: center;
	font-size: 1.5em;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
}
.filterDropDownIcons:hover {
	transform: scale(1.1);
}
.filterCheck {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	color: #4b9cd3;
}
.filterGrey {
	height: 25px !important;
	width: 25px !important;
	margin-right: 23px;
	margin-left: 5px;
	margin-bottom: 7.5px;
	margin-top: 7.5px;
	font-size: 1.5em;
	color: #e5e5e5;
}

.filterItemText {
	width: fit-content !important;
	text-overflow: ellipsis !important;

	margin-left: 20px;
	font-size: 15px;
	line-height: 20px;
	align-items: center;
	margin-bottom: 0px;
}
.filterItem {
	background-color: #ffffff;
	width: 15vw;
	align-items: center;
	height: auto;
}
.filterList {
	margin: 0;
	padding-left: 0;
	list-style: none;
}
ul {
	list-style: none;
}
.filterLi {
	height: 40px;
	padding-left: 0;
	border-collapse: collapse;
}
.filterScroll {
	max-height: calc(100vh - 145px - 15px) !important;
	overflow-y: auto !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.filterScroll::-webkit-scrollbar {
	display: none;
}
