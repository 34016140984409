.SideCardBody {
	/*flex: 1 1 auto; */
	min-height: 1px;
	display: flex;
	/*flex-direction: column;*/
	align-content: center;
	flex-wrap: wrap;
	justify-content: center;
	padding-left: 1vw !important;
	padding-right: 1vw !important;
	padding-top: 1vh !important;
	border-radius: 0px;
}

#SideCardCard {
	width: 25vw;
	height: calc(100vh-105px);
	height: auto;
	background-color: white;
	border: none;
}

.SideCardProfileHeader {
	display: flex;
	justify-content: center !important;
	padding: 0 !important;
	background-color: #4b9cd3 !important;
	width: 25vw;
	border-radius: 0px !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.flex-container {
	display: flex;
}

.card-header {
	padding: 0;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
#ProfileDiv {
	height: '190px';
	width: '25vw';
	flex-wrap: 'wrap';
	padding-top: '20px';
}
.emailDiv {
	width: inherit;
	display: flex;
	justify-content: center;
	/* font-family: Droid Sans; */
	font-style: normal;
	font-weight: normal;
	color: white;
	font-size: 20px;
	padding-top: 5px;
}
.nameDiv {
	padding-top: 10px;
	margin-left: 10px;
	height: 100px;
	text-align: center;
	display: flex;
	flex-flow: column;
	line-height: 30px;
	color: white;
	width: '12vw';
	display: 'flex';
	flex-wrap: 'wrap';
	justify-content: 'center';
}
.imgDiv {
	height: 100px;
	margin-right: 10px;
	display: flex;
	align-items: center;
}
.nameText {
	font-style: 'normal';
	font-weight: 'normal';
	font-size: '40px';
	font-weight: 400px;
	line-height: '40px';
	display: 'flex';
	align-items: 'center';
	text-align: 'center';
	min-width: '51%';
	width: 'auto';
	text-align: 'center';
	margin-bottom: 0px;
}

.SideResumePdfImage {
	border-radius: 150px;
	height: 6vw !important;
	width: 6vw !important;

	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.settingsIcon {
	position: absolute;
	left: 5px;
	top: 5px;
	transition: all 0.2s ease-in-out;
}
.settingsIcon:hover {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
