.authModal {
	max-width: 60vw !important;
	margin-left: 20vw;
	margin-right: 20vw;
}

.authModalHeader {
	background-color: #4b9cd3;
	color: #fff;
}

.authModalSectionTitle {
	font-size: 30px;
}
