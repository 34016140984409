.studentViewModal{
    max-width: 80vw !important;
    margin-left: 10vw;
    margin-right: 10vw;

}

.studentModalHeader{
    background-color: #4b9cd3;
    color:#fff;
}

.studentModalSectionTitle{
    font-size: 30px;
    
}