.App {
  margin: 0px;
  height: 100vh !important;
  overflow-y: hidden;
  background-color: #13294b;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 1em;
  display: none;
}